import { ScComponent } from '@/types/interfaces';
import React, { SVGProps } from 'react';
import * as Icons from './icons';

export enum IconIds {
  Apple = 'Apple',
  BlackApple = 'BlackApple',
  Battery = 'Battery',
  BatterySize = 'BatterySize',
  Car = 'Car',
  CarToLeft = 'CarToLeft',
  CarToRight = 'CarToRight',
  Contract = 'Contract',
  Copy = 'Copy',
  Cross = 'Cross',
  Danger = 'Danger',
  Driving = 'Driving',
  Drivetrain = 'Drivetrain',
  DropdownArrow = 'DropdownArrow',
  ElectricityBar = 'ElectricityBar',
  Experian = 'Experian',
  Facebook = 'Facebook',
  FacebookBrand = 'FacebookBrand',
  FastCharger = 'FastCharger',
  Filters = 'Filters',
  HertzLogoPrimary = 'HertzLogoPrimary',
  GasStation = 'GasStation',
  Google = 'Google',
  Grid = 'Grid',
  HandShake = 'HandShake',
  HertzEllipse = 'HertzEllipse',
  HertzLogo = 'HertzLogo',
  Hybrid = 'Hybrid',
  Information = 'Information',
  Information2 = 'Information2',
  Instagram = 'Instagram',
  Lightning = 'Lightning',
  LinkedIn = 'LinkedIn',
  LinkedInBrand = 'LinkedInBrand',
  List = 'List',
  Loader = 'Loader',
  Mail = 'Mail',
  Message = 'Message',
  Microsoft = 'Microsoft',
  Mileage = 'Mileage',
  OilBar = 'OilBar',
  OilBarWhite = 'OilBarWhite',
  Performance = 'Performance',
  Pen = 'Pen',
  PlugInCar = 'PlugInCar',
  PlusBold = 'PlusBold',
  QueueList = 'QueueList',
  Range = 'Range',
  Seats = 'Seats',
  Share = 'Share',
  SquareTwoByTwo = 'SquareTwoByTwo',
  SMS = 'SMS',
  SMSEdit = 'SMSEdit',
  Sun = 'Sun',
  TikTok = 'TikTok',
  Twitter = 'Twitter',
  TwitterBrand = 'TwitterBrand',
  UserProfile = 'UserProfile',
  Verse = 'Verse',
  Calculator = 'Calculator',
  XMark = 'XMark',
  IncentiveMoneySign = 'IncentiveMoneySign',
  IncentiveMoneySignFull = 'IncentiveMoneySignFull',
  Spinner = 'Spinner',
  DownloadReport = 'DownloadReport',
  Gas = 'Gas',
  Triangle = 'Triangle',
  Sedan = 'Sedan',
  Hatchback = 'Hatchback',
  Coupe = 'Coupe',
  Suv = 'Suv',
  Truck = 'Truck',
  Make_Abarth = 'Make_Abarth',
  Make_Acura = 'Make_Acura',
  Make_Afeela = 'Make_Afeela',
  Make_AlfaRomeo = 'Make_AlfaRomeo',
  Make_Apple = 'Make_Apple',
  Make_Arrival = 'Make_Arrival',
  Make_AstonMartin = 'Make_AstonMartin',
  Make_Audi = 'Make_Audi',
  Make_BMW = 'Make_BMW',
  Make_Bentley = 'Make_Bentley',
  Make_Buick = 'Make_Buick',
  Make_BYD = 'Make_BYD',
  Make_Cadillac = 'Make_Cadillac',
  Make_Canoo = 'Make_Canoo',
  Make_Chevrolet = 'Make_Chevrolet',
  Make_Chrysler = 'Make_Chrysler',
  Make_Cruise = 'Make_Cruise',
  Make_Dacia = 'Make_Dacia',
  Make_Dodge = 'Make_Dodge',
  Make_Faraday = 'Make_Faraday',
  Make_Ferrari = 'Make_Ferrari',
  Make_FIAT = 'Make_FIAT',
  Make_Fisker = 'Make_Fisker',
  Make_Ford = 'Make_Ford',
  Make_GMC = 'Make_GMC',
  Make_Genesis = 'Make_Genesis',
  Make_Honda = 'Make_Honda',
  Make_Hyundai = 'Make_Hyundai',
  Make_INFINITI = 'Make_INFINITI',
  Make_Jaguar = 'Make_Jaguar',
  Make_Jeep = 'Make_Jeep',
  Make_Kia = 'Make_Kia',
  Make_Lamborghini = 'Make_Lamborghini',
  Make_LandRover = 'Make_LandRover',
  Make_Lexus = 'Make_Lexus',
  Make_Lincoln = 'Make_Lincoln',
  Make_Lotus = 'Make_Lotus',
  Make_Lucid = 'Make_Lucid',
  Make_MINI = 'Make_MINI',
  Make_Maserati = 'Make_Maserati',
  Make_Mazda = 'Make_Mazda',
  Make_McLaren = 'Make_McLaren',
  Make_MercedesBenz = 'Make_MercedesBenz',
  Make_Mitsubishi = 'Make_Mitsubishi',
  Make_Karma = 'Make_Karma',
  Make_Nissan = 'Make_Nissan',
  Make_Polestar = 'Make_Polestar',
  Make_Porsche = 'Make_Porsche',
  Make_Ram = 'Make_Ram',
  Make_Rimac = 'Make_Rimac',
  Make_Rivian = 'Make_Rivian',
  Make_RollsRoyce = 'Make_RollsRoyce',
  Make_Smart = 'Make_Smart',
  Make_Ssangyong = 'Make_Ssangyong',
  Make_Subaru = 'Make_Subaru',
  Make_Suzuki = 'Make_Suzuki',
  Make_Tesla = 'Make_Tesla',
  Make_Toyota = 'Make_Toyota',
  Make_VinFast = 'Make_VinFast',
  Make_Volkswagen = 'Make_Volkswagen',
  Make_Volvo = 'Make_Volvo',
  Make_Lordstown = 'Make_Lordstown',
  Make_NuRide = 'Make_NuRide',
  Minivan = 'Minivan',
  Wagon = 'Wagon',
  Van = 'Van',
  SedanSolid = 'SedanSolid',
  HatchbackSolid = 'HatchbackSolid',
  CoupeSolid = 'CoupeSolid',
  SuvSolid = 'SuvSolid',
  TruckSolid = 'TruckSolid',
  MinivanSolid = 'MinivanSolid',
  WagonSolid = 'WagonSolid',
  VanSolid = 'VanSolid',
  SedanDisabled = 'SedanDisabled',
  HatchbackDisabled = 'HatchbackDisabled',
  CoupeDisabled = 'CoupeDisabled',
  SuvDisabled = 'SuvDisabled',
  TruckDisabled = 'TruckDisabled',
  MinivanDisabled = 'MinivanDisabled',
  WagonDisabled = 'WagonDisabled',
  VanDisabled = 'VanDisabled',
  DownPolygon = 'DownPolygon',
  EVChargerPin = 'EVChargerPin',
  Level1Charger = 'Level1Charger',
  Level2Charger = 'Level2Charger',
  Level3Charger = 'Level3Charger',
  BatteryChargerLow = 'BatteryChargerLow',
  BatteryChargerMedium = 'BatteryChargerMedium',
  BatteryChargerHigh = 'BatteryChargerHigh',
  BatteryChargerNA = 'BatteryChargerNA',
  SAEJ1772Connector = 'SAEJ1772Connector',
  CCSConnector = 'CCSConnector',
  CHAdeMOConnector = 'CHAdeMOConnector',
  NACSConnector = 'NACSConnector',
  NAConnector = 'NAConnector',
  ElectricityBarWhite = 'ElectricityBarWhite',
  Snowflake = 'Snowflake',
  QuestionCircle = 'QuestionCircle',
  RangePin = 'RangePin',
}

export type IconId = keyof typeof Icons | keyof typeof IconIds | IconIds;

export interface IconProps
  extends ScComponent,
    Pick<React.SVGProps<SVGSVGElement>, 'width' | 'height' | 'x' | 'y'> {
  iconId: IconId;
  checkedFill?: string;
  strokeColor?: string;
  fillColor?: string;
}

const Icon: React.FC<IconProps> = ({
  className = '',
  iconId,
  width,
  height,
  strokeColor,
  fillColor,
}) => {
  const Svg = Icons[iconId];
  if (React.isValidElement<SVGProps<unknown>>(Svg)) {
    const childrenWithProps = React.Children.map(
      Svg.props.children,
      (child) => {
        if (React.isValidElement(child)) {
          const { stroke, fill } = child.props as React.ComponentProps<'path'>;
          return React.cloneElement(child as React.ReactElement, {
            ...(strokeColor && stroke && { stroke: strokeColor }),
            ...(fillColor && fill && { fill: fillColor }),
          });
        }
        return child;
      }
    );

    return React.cloneElement(
      Svg as React.ReactElement,
      {
        ...(width && { width }),
        ...(height && { height }),
        fill: fillColor || Svg.props.fill,
        stroke: strokeColor || Svg.props.stroke,
        className,
      },
      childrenWithProps
    );
  }

  return <div>Invalid Icon</div>;
};

export default Icon;
